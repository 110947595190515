import axios from 'axios';
import store from '@/store';
import routerNames from '@/router/routerNames';
import router from '@/router';

/**
 * Pass all urls by categories here
 */
const urls = {
  auth: {
    login: 'login/',
    logout: 'logout/',
    signToken: 'sign-token/',
    loginEmail: 'login-email/',
    forgotPassword: 'forgot-password/',
    resetPassword: 'reset-password/',
    csrfToken: '/sanctum/csrf-cookie/',
    apiToken: '/tokens/create/',
  },
  settings: {
    list: 'api/settings/',
    get detail() {
      return `${this.list}:settingId/`;
    }
  },
  users: {
    list: 'api/users/',
    detail: 'api/users/:userId/',
    detachOrganization: 'api/users/:userId/detach-organization/',
    connect: 'api/users/connect-to-organisation/:organizationId/',
    storageGroups: 'api/users/:userId/storage-group/:groupId/',
    updateEmail: 'api/users/update-email/',
    updatePassword: 'api/users/update-password/'
  },
  exhibits: {
    list: 'api/exhibits/',
    get bulkSignData() {
      return `${this.list}sign-data/bulk/`;
    },
    detail: 'api/exhibits/:exhibitId/',
    get count() {
      return `${this.list}count/`;
    },
    get copy() {
      return `${this.detail}copy/`;
    },
    get materials() {
      return `${this.detail}materials/:materialId/`;
    },
    get techniques() {
      return `${this.detail}techniques/:techniqueId/`;
    },
    storageGroups: 'api/storage-groups/:groupId/exhibits/:exhibitId/',
    collections: 'api/collections/:collectionId/exhibits/:exhibitId/',
    persons: 'api/persons/:personId/exhibits/:exhibitId/',
    get characteristics() {
      return `${this.detail}characteristic-values/:characteristicId/`;
    },
    get getSignData() {
      return `${this.detail}sign-data/`;
    },
    get sign() {
      return `${this.detail}sign/`;
    },
    get signaturesCountByClassification() {
      return `${this.list}signatures-count/classifications/:id/`;
    },
    get signaturesCountByCollection() {
      return `${this.list}signatures-count/collections/:id/`;
    },
    get signaturesCountByMaterial() {
      return `${this.list}signatures-count/materials/:id/`;
    },
    get signaturesCountByTechnique() {
      return `${this.list}signatures-count/techniques/:id/`;
    },
    get signaturesCountByPersons() {
      return `${this.list}signatures-count/persons/:id/`;
    },
    get signaturesCountByStorageGroup() {
      return `${this.list}signatures-count/storage-groups/:id/`;
    },
    get signaturesCountByStorageLocation() {
      return `${this.list}signatures-count/storage-locations/:id/`;
    },
    get import() {
      return `${this.list}import/`;
    }
  },
  exhibitNames: {
    list: 'api/exhibit-names/',
    get detail() {
      return `${this.list}:nameId/`;
    }
  },
  numbers: {
    list: 'api/numbers/',
    get detail() {
      return `${this.list}:numberId/`;
    }
  },
  organizations: {
    list: 'api/organizations/',
    get detail() {
      return `${this.list}:organizationId/`;
    },
    get search() {
      return `${this.list}live-search/`;
    },
    get upload() {
      return `${this.list}upload/`;
    },
  },
  positions: {
    list: 'api/positions/',
    detail: 'api/positions/:positionId/'
  },
  storageGroups: {
    list: 'api/storage-groups/',
    get detail() {
      return `${this.list}:groupId/`;
    }
  },
  storageLocations: {
    list: 'api/storage-locations/',
    get detail() {
      return `${this.list}:locationId/`;
    }
  },
  classifications: {
    list: 'api/classifications/',
    get detail() {
      return `${this.list}:classificationId/`;
    },
    get addCharacteristic() {
      return `${this.detail}characteristics/:characteristicId/`;
    },
    get removeCharacteristic() {
      return `${this.detail}characteristics/:characteristicId/`;
    }
  },
  characteristics: {
    list: '/api/characteristics/',
    get detail() {
      return `${this.list}:characteristicId/`;
    },
  },
  materials: {
    list: 'api/materials/',
    get detail() {
      return `${this.list}:materialId/`;
    },
    get techniques() {
      return `${this.detail}techniques`;
    },
    get addTechnique() {
      return `${this.detail}techniques/:techniqueId/`;
    },
    get removeTechnique() {
      return `${this.detail}techniques/:techniqueId/`;
    }
  },
  techniques: {
    list: 'api/techniques/',
    detail: 'api/techniques/:techniqueId/'
  },
  linearReferences: {
    list: 'api/linear-references/',
    get detail() {
      return 'api/linear-references/:referenceId/';
    }
  },
  linearReferencesValue: {
    list: 'api/linear-references-values/',
    detail: 'api/linear-references-values/:valueId/'
  },
  persons: {
    list: 'api/persons/',
    get count() {
      return `${this.list}count/`;
    },
    get detail() {
      return `${this.list}:personId/`;
    },
    get copy() {
      return `${this.detail}copy/`;
    }
  },
  collections: {
    list: 'api/collections/',
    get detail() {
      return `${this.list}:collectionId/`;
    },
  },
  sizes: {
    detail: 'api/sizes/:sizeId/',
    exhibit: 'api/exhibit/:exhibitId/sizes/',
    fragment: 'api/fragment/:fragmentId/sizes/',
  },
  prices: {
    list: 'api/prices/',
    get detail() {
      return `${this.list}:priceId/`;
    },
  },
  images: {
    exhibit: 'api/exhibit/:exhibitId/images/',
    detail: 'api/images/:imageId/',
    get person() {
      return `${this.detail}persons/:personId`;
    }
  },
  conditions: {
    list: 'api/conditions/',
    get detail() {
      return `${this.list}:conditionId/`;
    },
    get person() {
      return `${this.detail}persons/:personId`;
    }
  },
  events: {
    list: 'api/events/',
    get detail() {
      return `${this.list}:id/`;
    },
    get persons() {
      return `${this.detail}persons/:personId/`;
    },
  },
  gemstones: {
    list: 'api/gemstones/',
    get detail() {
      return `${this.list}:id/`;
    }
  },
  preciousMetals: {
    list: 'api/precious-metals/',
    get detail() {
      return `${this.list}:id/`;
    }
  },
  exhibitDescriptions: {
    list: 'api/exhibit-descriptions/',
    get detail() {
      return `${this.list}:descriptionId/`;
    }
  },
  compositions: {
    list: 'api/compositions/',
    get detail() {
      return `${this.list}:compositionId/`;
    }
  },
  transferStatements: {
    list: 'api/transfer-statements/',
    get detail() {
      return `${this.list}:id/`;
    },
    get signData() {
      return `${this.detail}sign-data/`;
    },
    get sign() {
      return `${this.detail}sign/`;
    },
    get person() {
      return `${this.detail}persons/:personId`;
    },
    get file() {
      return `${this.detail}files/`;
    },
    get exhibits() {
      return `${this.detail}exhibits/`;
    },
    get sendFiles() {
      return `${this.detail}send-files/`;
    },
    get exhibitsAction() {
      return `${this.exhibits}:exhibitId`;
    },
  },
  coordinatorStatements: {
    list: 'api/coordinator-statements/',
    get detail() {
      return `${this.list}:id/`;
    },
    get signData() {
      return `${this.detail}sign-data/`;
    },
    get sign() {
      return `${this.detail}sign/`;
    },
    get person() {
      return `${this.detail}persons/:personId`;
    },
    get file() {
      return `${this.detail}files/`;
    },
    get exhibits() {
      return `${this.detail}exhibits/`;
    },
    get transferStatements() {
      return `${this.detail}transfer-statements/`;
    },
    get sendFiles() {
      return `${this.detail}send-files/`;
    },
  },
  exhibitions: {
    list: 'api/exhibitions/',
    get detail() {
      return `${this.list}:id/`;
    },
    get exhibits() {
      return `${this.detail}exhibits/`;
    },
    get transferStatements() {
      return `${this.detail}transfer-statements/:statementId/`;
    },
    get coordStatements() {
      return `${this.detail}coordinator-statements/:statementId/`;
    },
    image: 'api/exhibition/:id/images/'
  },
  transfers: {
    list: 'api/transfers/',
    get detail() {
      return `${this.list}:id/`;
    },
    get person() {
      return `${this.detail}persons/:personId`;
    },
    get exhibits() {
      return `${this.detail}exhibits/`;
    },
    get exhibit() {
      return `${this.exhibits}:exhibitId/`;
    },
    get file() {
      return `${this.detail}files/`;
    },
  },
  file: {
    detail: 'api/files/:id/',
  },
  dataGovUa: {
    list: 'api/data-gov-ua-registries/',
    get detail() {
      return `${this.list}:registryId/`;
    }
  }
};

class ClientMixin {
  /** @param {API | DocumentsApi} client */
  constructor(client) {
    this.api = client;
  }
}

function ApiError(error) {
  this.status = error.response?.status;
  this.data = error.response?.data;
  this.detail = '';
  const messages = [];
  if (!error.response?.data) {
    this.detail = error;
    console.error(error);
    return;
  }
  if (error.response.data?.errors) {
    Object.entries(error.response.data?.errors)
      .forEach((el) => messages.push(`${el[0]}: ${el[1].join(' ')}`));
  } else {
    messages.push(error.response.data.message);
  }
  this.detail = messages.join('; ');
  console.error(this.detail);

  /** @param {object} formErrors fields error dict */
  this.throwFormErrors = (formErrors) => {
    if (this.status !== 422) {
      return;
    }
    const errLst = Object.keys(error.response.data?.errors);
    if (errLst.length) {
      errLst.forEach((err) => formErrors[err] = error.response.data.errors[err].join(' '));
    }
  };
}

async function _request(func, ...args) {
  let response = null;
  let error = null;
  try {
    response = await func(...args);
  } catch (e) {
    error = new ApiError(e);
    if (error.status === 401) {
      store.commit('setUser', {});
      await router.push({name: routerNames.AUTH.login});
    }
  }
  return [response, error];
}

const _axios = axios.create({
  withXSRFToken: true,
  withCredentials: true,
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    'Accept': 'application/json;',
    'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE,OPTIONS',
    'Access-Control-Allow-Origin': '*',
  }
});

export {
  urls,
  ClientMixin,
  _request,
  _axios,
};
