import {createApp} from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import BreadCrumbs from '@/components/common/BreadCrumbs';
import AppFooter from '@/components/common/AppFooter';
import VClickOutside from '@/directives/VClickOutside';
import '@/utils/proto';
import 'v-calendar/dist/style.css';

window.addEventListener('load', () => store.commit('setReady', true));

createApp(App)
  .component('BreadCrumbs', BreadCrumbs)
  .component('AppFooter', AppFooter)
  .directive(VClickOutside.name, VClickOutside)
  .use(store)
  .use(router)
  .mount('#app');
